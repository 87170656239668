<template>
    <div>
        <div class="default_box" v-if="preLoad" itemscope itemtype="http://schema.org/Event">
            <div class="default_header" v-if="item">
                <h1 v-html="item.name"></h1>
                <meta itemprop="name" :content="item.name">
                <meta itemprop="description" :content="item.description ? $cutText(item.description, 100) : item.name">
            </div>
            <div class="default_box_content bg_white p15" v-if="item">
                <imageLoader v-if="item.image" :src="item.image" :align="true"></imageLoader>
                <p class="default_item_text" v-if="item.description" v-html="$htmContext(item.description)" itemprop="description"></p>
                <time v-if="item.start_at" itemprop="startDate" :datetime="$moment(item.start_at).format('YYYY-MM-DDTHH:mm')"></time>
                <time v-if="item.end_at" itemprop="endDate" :datetime="$moment(item.end_at).format('YYYY-MM-DDTHH:mm')"></time>
                <p class="poster_text_contact link_box mb-2" v-if="item.start_at !== null && $moment(item.start_at).format('DD.MM.YY') == $moment(item.end_at).format('DD.MM.YY')">
                    <img src="/images/icon/clock.svg" alt="icon" class="icon_link">
                    <b class="ml-2">Дата:</b> {{$moment(item.start_at).format('DD.MM.YY, начало: HH:mm')}}</p>
                <p class="poster_text_contact link_box mb-2" v-else-if="item.start_at !== null && item.end_at !== null">
                    <img src="/images/icon/clock.svg" alt="icon" class="icon_link">
                    <b class="ml-2">Дата:</b> {{$moment(item.start_at).format('DD.MM.YYYY')}} - {{ $moment(item.end_at).format('DD.MM.YYYY')}} <b>Начало:</b> {{$moment(item.start_at).format('HH:mm')}}</p>
                <p class="poster_text_contact link_box mb-2" v-else-if="item.start_at !== null">
                    <img src="/images/icon/clock.svg" alt="icon" class="icon_link">
                    <b class="ml-2">Дата:</b> {{$moment(item.start_at).format('DD.MM.YY HH:mm')}}</p>
                <p class="poster_text_contact link_box mb-2" v-if="item.address" itemprop="location" itemscope itemtype="http://schema.org/Place">
                    <span v-if="$myCity" itemprop="name" v-html="$myCity.name" style="display: none"></span>
                    <span v-if="$myCity" itemprop="address" v-html="item.address" style="display: none"></span>
                    <img src="/images/icon/geolocation1.svg" alt="icon" class="icon_link">
                    <b class="ml-2">Адрес: </b>{{item.address}}</p>
                <p class="poster_text_contact link_box mb-2" v-if="item.price">
                    <img src="/images/icon/ruble.svg" alt="icon" class="icon_link">
                    <b class="ml-2">Стоимость: </b>{{item.price.substring(6)}}</p>
                <p class="poster_text_contact link_box mb-2" v-if="item.telephone">
                    <img src="/images/icon/call.svg" alt="icon" class="icon_link">
                    <b class="ml-2">Телефон: </b><a :href="'tel:' + item.telephone" itemprop="telephone">{{item.telephone}}</a></p>
                <p class="poster_text_contact link_box mb-2" v-if="item.url">
                    <img src="/images/icon/link.svg" alt="icon" class="icon_link">
                    <b class="ml-2">Ссылка: </b><a :href="item.url" target="_blank" class="link_carryover" rel="nofollow" itemprop="url">{{item.url}}</a></p>
            </div>
            <router-link v-if="item.organization" :to="{name:'OrganisationGet', params: {id:item.organization.id}}">
                <div class="default_box_content bg_white p15 default_organisation_box">
                    <b-row class="p-0">
                        <b-col cols="12" sm="10">
                            <p>Организация в справочнике</p>
                            <div>{{item.organization.name}}</div>
                        </b-col>
                        <b-col cols="12" sm="2">
                            <img :src="item.organization.image" :alt="item.organization.name">
                        </b-col>
                    </b-row>
                </div>
            </router-link>
            <div class="default_box_content bg_white p15">
                <div class="default_item_footer" style="border-top: 0px !important; padding: 0;">
                    <ShareButtons :title="item.name" :link="'https://okaygorod.com' + this.$route.fullPath" :image="item.image ? item.image : null" />
                    <span v-html="$moment(item.created_at).local().format('DD.MM.YYYY в HH:mm')" class="default_item_footer_date_news"></span>
                </div>
            </div>
            <VkFrame v-if="item && item.vk_video && item.vk_video.url" :item="item.vk_video"></VkFrame>
            <YouTube v-if="item.youtube" :item="item.youtube"></YouTube>
        </div>
        <div v-else>
            <PreLoadBox></PreLoadBox>
        </div>
    </div>
</template>
<script>
import imageLoader from "@/components/ImageLoader/imageLoader";
import YouTube from '@/components/YouTube/index';
import PreLoadBox from "../../Preloader/coin";
import ShareButtons from '@/components/Plagins/ShareButtons';
import VkFrame from '@/components/Plagins/VkFrame/index';

export default {
    name: "Post",
    components: {
        PreLoadBox,
        YouTube,
        VkFrame,
        imageLoader,
        ShareButtons
    },
    metaInfo() {
        return {
            title: this.item && this.item.name ? `${this.item.name} - ` : null,
            meta: [
                { name: 'description', itemprop: 'description', content: this.item && this.item.description ? `${this.item.description}` : `Афиша мероприятий города ${this.$myCity.name}. Кино, концерты, театры, городские мероприятия. Так же покупка билетов онлайн, телефоны и адреса на сайте Окей Город ${this.$myCity.name}.` },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: this.item && this.item.name ? `${this.item.name} - ${this.$myCity.name}` : `Афиша - ${this.$myCity.name}` },
                { property: 'og:description', content: this.item && this.item.description ? `${this.item.description}` : `Афиша мероприятий города ${this.$myCity.name}. Кино, концерты, театры, городские мероприятия. Так же покупка билетов онлайн, телефоны и адреса на сайте Окей Город ${this.$myCity.name}.` },
                { property: 'og:image', content: this.item && this.item.image ? this.item.image : 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'article' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            item: {},
            orgid: null,
            org: {},
            // next: null,
            // prev: null
        }
    },
    methods: {
        api() {
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + '/Poster.get';
            this.$http.get(url, {
                params: {
                    id: this.$route.params.id
                }
            }).then((response) => {
                this.item = response.data.response.item;
                this.orgid = response.data.response.item.org_id;
                if (this.orgid) {
                    this.org = this.get_org(this.orgid);
                }
            }).finally(() => {
                this.preLoad = 'finish';
            })
        },
        get_org(id) {
            let url = this.$config.api_url + this.$route.params.city + '/Organizations.get';
            return this.$http.get(url, {
                params: {
                    id: id,
                }
            }).then((response) => {
                return response.data.response.item;
            }).catch(() => {
                return null
            })
        }

    },
    mounted() {
        this.api();
    },
    computed: {
        shareurl: {
            get: function() {
                return 'https://okaygorod.com/' + this.$route.params.city + '/poster/' + this.$route.params.id;
            }
        }
    },
}
</script>
<style scoped>
p {
    text-align: initial;
    padding: 0 5px 0 5px;
}

.poster_org {
    border-top: 1px solid #d8d8d8;
    padding: 5px;
    transition: .5s;
}

.poster_org a {
    text-decoration: none;
    color: #2c3e50;
    transition: .5s;
}

.poster_org:hover a {
    color: #fff;
}

.poster_org:hover {
    background: #4a76a8;
    ;

}

.icon_link {
    width: 20px;
    height: 20px;
}

.link_box {
    line-height: 24px;

}

.icon {
    max-width: 40px;
    max-height: 40px;
    margin: 5px;
}
</style>